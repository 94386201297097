<template>
  <Modal :style="computedWindowSize"
         :visible="visible"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div class="p-dialog-title">Adding item to warehouse</div>
    </template>
    <Spinner v-if="dataIsSending"></Spinner>
    <div v-else class="p-formgrid p-grid p-pt-3">
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5">
        <div class="p-float-label">
          <InputText id="reduced_position_code_modal" disabled v-model="itemData.code" autocomplete="new-password"/>
          <label for="reduced_position_code_modal">{{ $t('Code') }}</label>
        </div>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5">
        <div class="p-float-label">
          <InputText id="reduced_position_name_modal" disabled v-model="itemData.name" autocomplete="new-password"/>
          <label for="reduced_position_name_modal">{{ $t('Name') }}</label>
        </div>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5">
        <div class="p-float-label">
          <InputNumber id="reduced-position-qty-modal"
                       :locale="computedNumberInputLocale"
                       :inputClass="{'p-invalid' : submitted && !itemData.qty}"
                       v-model="itemData.qty"
                       mode="decimal"
                       disabled
                       :min="0"
                       :minFractionDigits="2"
                       :maxFractionDigits="4"
                       autocomplete="off"/>
          <label for="reduced-position-qty-modal">Qty<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !itemData.qty">{{ $t('Required field') }}</small>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5">
        <div class="p-float-label">
          <InputNumber id="reduced-position-purchase-price-modal"
                       :locale="computedNumberInputLocale"
                       :inputClass="{'p-invalid' : submitted && !itemData.purchase_price}"
                       v-model="itemData.purchase_price"
                       mode="decimal"
                       disabled
                       :min="0"
                       :minFractionDigits="2"
                       :maxFractionDigits="4"
                       autocomplete="off"/>
          <label for="reduced-position-purchase-price-modal">Purchase price<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !itemData.purchase_price">{{ $t('Required field') }}</small>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">
        <div class="p-float-label dropdown-wrapper table-body__name-input--first">
          <CustomDropdown id="add-reduced-position-warehouse-item"
                          class="dropdown__select dropdown__select--one-button-width dropdown__select--border-radius"
                          v-model="selectedWarehouseItem"
                          @filter="searchWarehouseItems($event)"
                          placeholder="Select item"
                          :clearSearchData="!visible"
                          :options="warehouseItemsData"
                          :filter="true"
                          :filterFields="['code', 'raw_code', 'name']"
                          :showClear="true">
            <template #value="slotProps">
              <div v-if="slotProps.value">
                <span>{{ slotProps.value.name }}<span v-if="slotProps.value.code" style="">, Code: {{ slotProps.value.code }}</span></span>
              </div>
<!--              <span v-else>Select warehouse item<span class="warning-color">*</span></span>-->
              <span v-else style="visibility: hidden">.</span>
            </template>
            <template #option="slotProps">
              <div>
                <div>{{ slotProps.option.name }}<span v-if="slotProps.option.code" style="">, Code: {{ slotProps.option.code }}</span></div>
                <span class="p-mt-1" style="font-size: 0.9rem">Qty: {{ formatDecimal(slotProps.option.qty) }}, Reserved: {{ formatDecimal(slotProps.option.reserved_qty) }}, Available: {{ formatDecimal(slotProps.option.qty - slotProps.option.reserved_qty) }}</span>
              </div>
            </template>
          </CustomDropdown>
<!--          <label for="add-reduced-position-warehouse-item">Warehouse item<span class="warning-color">*</span></label>-->
          <Button @click="addNewWarehouseItem" class="dropdown__create-button p-d-flex p-ai-center p-jc-center"><i class="ti-plus"></i></Button>
        </div>
<!--        <small class="p-invalid" v-if="submitted && !selectedWarehouseItem">{{ $t('Required field') }}</small>-->
      </div>
    </div>

    <template #footer>
      <Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-text" @click="close"/>
      <Button :label="$t('Save')" :disabled="disableSaveButton" icon="pi pi-check" class="p-button-text" @click="saveItem" />
    </template>

    <WarehouseItemModal :visible="warehouseItemModal"
                        :item="warehouseItem"
                        :disableQtyEditing="true"
                        @close="closeWarehouseItemModal"
                        @update-item="updateWarehouseItem">
    </WarehouseItemModal>
  </Modal>
</template>

<script>
import httpClient from "@/services/http.services";
import generateMixins from "@/mixins/generateMixins";
import httpMixins from "@/mixins/httpMixins";
import constants from "@/constants";
import formatMixins from "@/mixins/formatMixins";
import settings from "@/settings";
import WarehouseItemModal from "@/pages/warehouse/components/WarehouseItemModal";

export default {
  mixins: [ generateMixins, httpMixins, formatMixins ],
  emits: ['close', 'update-items', 'update-item'],
  components: { WarehouseItemModal },
  name: 'AddReducedPositionToWarehouseModal',
  props: {
    item: Object,
    categories: Array,
    visible: Boolean,
    selectedCategoryId: Number,
  },
  data() {
    return {
      dataIsSending: false,
      disableSaveButton: false,
      warehouseItem: null,
      warehouseItemModal: false,
      searchData: null,
      warehouseItemsData: [],
      selectedWarehouseItem: null,
      itemData: {},
      submitted: false,
      constants,
      settings,
    }
  },
  watch: {
    // categories(value) {
    //   console.log(value)
    // },
    item(value) {
      this.itemData = {
        ...value,
        code: value.position_data?.code,
        name: value.position_data?.name,
        purchase_price: value.position_data?.purchase_price ? +value.position_data.purchase_price : 0,
        sell_price: value.position_data?.sell_price ? +value.position_data.sell_price : 0,
        qty: value.qty ? +value.qty : 0,
      }

      if (value.warehouse_item) {
        this.selectedWarehouseItem = value.warehouse_item

        let warehouseItemExists = this.warehouseItemsData.find(i => i.id === value.warehouse_item.id)
        if (!warehouseItemExists) {
          this.warehouseItemsData.push(value.warehouse_item)
        }
      } else {
        this.selectedWarehouseItem = null
        this.warehouseItemsData = []
      }
    },
    '$store.state.secondLayerIsOpened'() {
      if (!this.$store.state.secondLayerIsOpened) {
        if (this.warehouseItemModal) {
          this.closeWarehouseItemModal()
        }
      }
    },
    visible() {
      if (!this.visible) {
        this.submitted = false
        this.selectedWarehouseItem = null
      }
    },
  },
  methods: {
    updateWarehouseItem(warehouseItem) {
      if (!warehouseItem) return false
      this.selectedWarehouseItem = warehouseItem
      if (warehouseItem.isNew) {
        this.warehouseItemsData.push(warehouseItem)
      }
      this.searchData = null
    },
    closeWarehouseItemModal() {
      this.warehouseItemModal = false
      this.warehouseItem = {}
      this.$store.commit('toggleSecondLayer', false)
    },
    async searchWarehouseItems(event) {
      let searchData = event.value
      if (searchData) {
        searchData = searchData.replace(' ','%20')
        try {
          const { status, data } = await httpClient('warehouse/get-items?search=' + searchData)
          if (status === 200 && data) {
            this.warehouseItemsData = data
          }
        } catch(err) {
          this.showError(err)
        }
      }
    },
    addNewWarehouseItem() {
      this.warehouseItem = {
        code: this.itemData.code,
        raw_code: this.itemData.raw_code ? this.itemData.raw_code : (this.itemData.code ? this.generateRawCode(this.itemData.code) : null),
        name: this.itemData.name,
        purchase_price: this.itemData.purchase_price,
        sell_price: this.itemData.sell_price,
      }
      this.warehouseItemModal = true

      this.$store.commit('toggleSecondLayer', true)
    },
    // changeCategory() { //Don't delete
    //   this.itemData.categoryWasChanged = this.itemData.warehouse_category_id !== this.selectedCategory?.id
    // },
    // changeCode(itemData, value) {
    //   itemData.raw_code = this.generateRawCode(value)
    // },
    async saveItem() {
      this.submitted = true
      // if (!this.selectedWarehouseItem) {
      //   return false
      // }

      this.dataIsSending = true
      this.disableSaveButton = true

      const item = {
        reducedPositionId: this.itemData?.id ?? null,
        warehouseItemId: this.selectedWarehouseItem?.id ?? null,
        qty: this.itemData?.qty ?? null,
      }

      try {
        const { data, status } = await httpClient.post(`reduced-position/add-to-warehouse`, item)
        if (status === 200 && data?.success) {
          this.$emit('update-items', this.itemData.id)
          this.$toast.add({severity: 'success', detail: this.$t('Data updated'), life: this.settings.toastLife});
          this.close()
        } else if (data?.error) {
          this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: this.settings.toastLife});
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.disableSaveButton = false
        this.dataIsSending = false
      }
    },
    close() {
      this.$emit('close')
      this.submitted = false
    },
  },
  computed: {
    // warehouseItemWasChanged() {
    //   return !!(this.itemData.warehouse_item_id !== this.selectedWarehouseItem?.id)
    // },
    computedWindowSize() {
      if (this.$store.state.mobileLayout) {
        return {position: 'absolute', top: 0, width: '95%', marginTop: '10px'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'absolute', top: 0, width: '550px', marginTop: '15px'}
      } else {
        return {position: 'absolute', top: 0, width: '550px'}
      }
    },
  },

}
</script>

<style scoped lang="scss">
.dropdown-wrapper {
  display: flex;
}

.dropdown__select {
  width: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.dropdown__select--one-button-width {
  width: calc(100% - 2.357rem);
}

.dropdown__create-button{
  width: 2.357rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>